  <template>
  <div class="container-fluid">
    <PrimePreloader v-if="loading"/>

    <div v-else-if="educationProgram" class="my-4">
      <h4 class="text-center my-3">Формирование ОП</h4>

      <div class="text-center mb-3">
        <h5 class="mb-2">
          {{ educationProgram?.education_speciality_name }}
        </h5>
        <div>
          {{ educationProgram?.education_speciality_code }}
        </div>
        <div>
          {{ educationProgram?.admission_year }} ({{ educationProgram?.language?.native_name }})
        </div>
      </div>


      <!-- Delete Modal -->
      <div class="modal fade" id="deleteEducationModuleModal" tabindex="-1"
           aria-labelledby="deleteEducationModuleModalLabel"
           aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Удаление модуля</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div v-if="deleteEducationModuleId">
                Вы точно хотите удалить этот модуль?
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                      @click="deleteEducationModule">
                Удалить
              </button>
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                Закрыть
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- End Delete Modal -->


      <div v-if="newModules.length">
        <div class="form-row" v-for="(newModule, index) in newModules"
             :key="index">

          <div class="education-field-remove-item__button d-flex flex-row-reverse">
            <i class="fa fa-times" @click="deleteRowEducationModuleInfo(index)"></i>
          </div>

          <div class="form-group row mt-4">
            <label for="education_module" class="col-md-3 col-form-label">Номер модуля</label>
            <div class="col-md-9">
              <input id="education_module" type="text" class="form-control" :value="newModule.module"
                     @input="changeNewModules(newModule, 'module', $event)">
            </div>

          </div>
          <div class="form-group row mt-4">
            <label for="education_module_name" class="col-md-3 col-form-label">Название модуля</label>
            <div class="col-md-9">
              <input id="education_module_name" type="text" class="form-control" :value="newModule.name"
                     @input="changeNewModules(newModule, 'name', $event)">
            </div>

          </div>

          <div class="form-group row mt-4">
            <label for="education_module_name" class="col-md-3 col-form-label">Описание модуля</label>
            <div class="col-md-9">
              <input id="education_module_description" type="text" class="form-control"
                     :value="newModule.description"
                     @input="changeNewModules(newModule, 'description', $event)">
            </div>

          </div>
          <hr>

        </div>
        <div class="form-group mt-4 text-center">
          <Button label="Сохранить" class="p-button-rounded" @click="saveNewModules"/>
        </div>
      </div>

      <input id="groupingIds" class="form-check-input m-2" type="checkbox"> <p class="mt-1" style="display: inline-block"> - объединение КВ</p><br>
      <input id="minorGroupingIds" class="form-check-input m-2" type="checkbox"> <p class="mt-1" style="display: inline-block"> - объединение майнора с КВ</p><br>
      <input id="groupingModularIds" class="form-check-input m-2" type="checkbox"> <p class="mt-2" style="display: inline-block"> - объединение модульных дисциплин</p><br>


      <div v-if="!checkSupervisor" class="form-group mt-3 d-inline-block">
        <Button icon="pi pi-plus" label="Добавить модуль" class="p-button-rounded p-button-secondary"
                @click="addRowEducationModuleInfo"/>
      </div>

      <div v-if="!checkSupervisor" class="form-group ms-3 d-inline-block">
        <!--        <router-link-->
        <!--            :to="'/education-program-minor?education_program_id=' + educationProgram.id +-->
        <!--            '&admission_year=' + educationProgram.admission_year + '&language_id=' + educationProgram.language_id +-->
        <!--            '&decree_goso_template_id=' + $route.query.decree_goso_template_id">-->
        <!--          <Button icon="pi pi-plus" label="Добавить майнор" class="p-button-rounded p-button-warning"/>-->
        <!--        </router-link>-->
      </div>

      <!-- Begin Modules -->
      <div class="row mt-4">
        <div v-for="(item, index) in formationEducationProgram_form.educationModules" :key="index">
          <div class="card mt-4">
            <div class="card-body">

              <div class="row my-1">
                <div class="col">
                  <b>{{ item.module }}: {{ item.name }}</b>
                </div>
                <div class="col text-end">
                  <button v-if="!checkSupervisor && item.module != 'Майнор'" type="button" class="btn" data-bs-toggle="modal"
                          data-bs-target="#deleteEducationModuleModal"
                          @click="openDeleteEducationModuleModal(item.id)">
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </div>
              </div>

              <div v-if="!checkSupervisor">
                <Button v-if="item.module != 'Майнор'" icon="pi pi-plus" class="p-button-rounded p-button-secondary"
                        @click="addRowFormationEducationProgram(item.id)"/>
              </div>

              <div class="row my-2 py-1 rounded-3"
                   v-for="(item, index) in formationEducationProgram_form.formationEducationProgramInfos.filter(i => i.education_module_id == item.id)"
                   :key="index"
                   :style="{backgroundColor: colorForFGroup[item.f_group_id] || ( item.is_accelerated_learning != 0 ? '#FF6666' : '#fff')}">
                <div class="col-md-2">
                  <select class="form-control form-select" id="goso_cycle_name_id"
                          @input="changeFormationEducationProgramData(item, 'goso_cycle_name_id', $event)"
                          :disabled="!!item?.id&&!item?.edit_status">
                    <option hidden>Выберите</option>
                    <option v-for="(cycle, index) in formationEducationProgram_form.gosoTemplateCycle"
                            :value="cycle.goso_cycle_name_id"
                            :key="index"
                            :selected="cycle.goso_cycle_name_id == item.goso_cycle_name_id">
                      {{ cycle.gosoCycleName.name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-2">
                  <select class="form-control form-select" name="component" id="component"
                          @change="changeFormationEducationProgramData(item, 'goso_component_name_id', $event)"
                          :disabled="(!!item?.id&&!item?.edit_status)||!!item.f_group_id">
                    <option hidden>Выберите</option>
                    <option
                        v-for="(component, index) in formationEducationProgram_form.componentNames"
                        :value="component.id"
                        :selected="component.id == item.goso_component_name_id"
                        :key="index">{{ component.name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-1">
                  <select class="form-control form-select" id="semestr"
                          @change="changeFormationEducationProgramData(item, 'semester', $event)"
                          :disabled="!!item?.id&&!item?.edit_status">
                    <option v-for="(semesters, index) in filteredEducationSemesters"
                            :value="semesters.id"
                            :key="index"
                            :selected="semesters.id == item.semester">
                      {{ semesters.name }}
                    </option>
                  </select>
                </div>

                <div class="col-md-4">
                  <!--                  <DropdownList-->
                  <!--                      :options="options"-->
                  <!--                      :value="item.educationDiscipline"-->
                  <!--                      @change="changeSelectFormationEducationProgramData(item, 'education_discipline_id', $event)"-->
                  <!--                      @search="onSearch"/>-->

                  <Dropdown
                      style="width: 100%"
                      :disabled="!!item?.id&&!item?.edit_status"
                      :modelValue="item.education_discipline_id"
                      :options="item.disciplines"
                      optionLabel="name"
                      optionValue="id"
                      placeholder="Выберите дисциплину"
                      :filter="true"
                      @filter="searchDisciplines($event.value, item)"
                      @change="changeFormationEducationProgramData(item, 'education_discipline_id', $event)"
                  />
                </div>

                <!--                <div class="col-md-1 pt-2">-->
                <!--                  <div v-if="item?.educationDiscipline?.credit">Кредит: {{item?.educationDiscipline?.credit}}</div>-->
                <!--                </div>-->

                <div class="col-md-1">
                  <input id="credit" type="number" class="form-control"
                         placeholder="Количество кредитов"
                         :value="item.credit"
                         @input="changeFormationEducationProgramData(item,'credit', $event)"
                         :disabled="!!item?.id&&!item?.edit_status">
                </div>

                <div v-if="!checkSupervisor" class="col-md-2 d-flex align-items-center">

                  <input v-if="[1, 2].includes(Number(item.goso_component_name_id))"
                         :disabled="!!item?.id&&!item?.edit_status"
                         id="summerFastDisciplines" style="width: 20px; height: 20px" class="form-check-input me-2"
                         type="checkbox" :checked="Boolean(Number(item.is_accelerated_learning))" :value="true"
                         @click="changeSelectFormationEducationProgramData(item, 'is_accelerated_learning', $event.target.checked)">

                  <!-- edit button -->
                  <Button v-if="!item?.id" icon="pi pi-pencil" class="p-button-rounded p-button-warning mx-1"
                          disabled="disabled"/>
                  <Button v-else-if="item?.edit_status" icon="pi pi-check" class="p-button-rounded mx-1"
                          @click="putFormation(item.id)"/>
                  <Button v-else icon="pi pi-pencil" class="p-button-rounded p-button-warning mx-1"
                          @click="changeSelectFormationEducationProgramData(item, 'edit_status', 1)"/>
                  <!-- end edit button -->

                  <Button v-if="item.f_group_id" label="КВ" icon="pi pi-times"
                          class="p-button-rounded p-button-danger p-button-outlined mx-1"
                          @click="deleteFormationFromGroup(item)"/>
                  <Button v-else-if="item.is_minor == '0'" else icon="pi pi-trash" class="p-button-rounded p-button-danger mx-1"
                          @click="deleteRowFormationEducationProgram(item)"/>

                  <Button v-if="item.minor_f_group_id != null" :label="'MIN: '+ item.minor_f_group_id" icon="pi pi-times"
                          class="p-button-rounded p-button-danger mx-1"
                          @click="deleteFormationFromMinorGroup(item.minor_f_group_id)"/>

                  <Button v-if="item.module_group_id != null" :label="'MOD: '+ item.module_group_id" icon="pi pi-times"
                          class="p-button-rounded p-button-danger mx-1" @click="deleteFormationFromModuleGroup(item.module_group_id)"/>

                  <input id="groupingIds"
                         v-if="!item.f_group_id&&item?.id&&kvIds.includes(+item.goso_component_name_id)&&item.is_minor == 0"
                         class="form-check-input m-2" type="checkbox" :value="item.id" v-model="groupingIds">
                  <input id="minorGroupingIds"
                         v-if="((item.f_group_id != null) || item.is_minor == 1) && item.minor_f_group_id == null"
                         class="form-check-input m-2" type="checkbox" :value="item" v-model="minorGroupingIds">
                  <input id="groupingModularIds"
                         v-if="!item.f_group_id && item.is_minor == 0 && !item.module_group_id && !kvIds.includes(+item.goso_component_name_id)"
                         class="form-check-input m-2" type="checkbox" :value="item.id" v-model="groupingModularIds">

                </div>

              </div>


            </div>
          </div>
        </div>
      </div>
      <!--  End Modules -->

      <div class="my-4 text-center" style="min-height: 100px">
        <Button v-if="!checkSupervisor" label="Создать" class="p-button-rounded"
                :disabled="!formationEducationProgram_form.formationEducationProgramInfos.find(v => !v.id)"
                @click="submitFormationEducationProgram"/>
      </div>


      <Sidebar v-model:visible="visibleBottom" :baseZIndex="1000" position="bottom" class="p-sidebar-lg">
        <div v-if="formationEducationProgram_form?.formationEducationProgramInfos.length">
          <div class="mb-3">
            <div class="my-1 text-center fw-bold">ГОСО</div>

            <div class="row m-0" style="background-color: #C5CAE9">
              <div class="col-md-3 border border-secondary"
                   v-for="(cycle, cycleIdx) in gosoG" :key="'cycle' + cycleIdx">
                <div class="row">
                  <div class="col-md-4 ps-2 fw-bold">
                    {{ cycle.cycleName }}
                  </div>

                  <div class="col-md-8" v-if="cycle.components.length">
                    <div class="row" v-for="(component, comIdx) in cycle.components" :key="`component${comIdx}`">
                      <div class="col-6 col-sm-6 col-md-6 border border-secondary">{{ component.name }}</div>
                      <div class="col-6 col-sm-6 col-md-6 border border-secondary">{{ component.credit }}</div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="text-left p-1 fw-bold">
              Итого: {{ allCreditsCountG }}
            </div>
          </div>

          <div>
            <div class="my-1 text-center fw-bold">ОП: {{ educationProgram?.education_speciality_name }}</div>

            <div class="row m-0">
              <div class="col-md-3 border border-secondary"
                   v-for="(cycle, cycleIdx) in goso" :key="'cycle' + cycleIdx">
                <div class="row">
                  <div class="col-md-4 ps-2 fw-bold">
                    {{ cycle.cycleName }}
                  </div>

                  <div class="col-md-8" v-if="cycle.components.length">
                    <div class="row" v-for="(component, comIdx) in cycle.components" :key="`component${comIdx}`">
                      <div class="col-6 col-sm-6 col-md-6 border border-secondary">{{ component.name }}</div>
                      <div class="col-6 col-sm-6 col-md-6 border border-secondary">{{ component.credit }}</div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="text-left p-1 fw-bold">
              Итого: {{ allCreditsCount }}
            </div>
          </div>
        </div>
      </Sidebar>

      <!-- credit count section -->
      <div class="fixed-bottom"
           v-if="formationEducationProgram_form?.formationEducationProgramInfos.length||groupingIds.length">

        <div class="text-center p-2">
          <Button v-if="formationEducationProgram_form?.formationEducationProgramInfos.length" icon="pi pi-arrow-up"
                  @click="visibleBottom = true" class="p-button-secondary p-button-rounded mx-2"/>
          <Button v-if="groupingIds.length" label="Сгруппировать КВ" @click="groupingDisciplines"
                  class="p-button-rounded mx-2"/>
          <Button v-if="minorGroupingIds.length" label="Сгруппировать майноры" @click="groupingMinorDisciplines"
                  class="p-button-rounded mx-2"
                  :disabled="isCvLength != 2 || isMinorLength != 1 || !isCreditEqual || !isFgroupIdEqual"/>
          <Button v-if="groupingModularIds.length" label="Сгруппировать модульные дисциплины"
                  @click="groupingModularDisciplines"
                  class="p-button-rounded mx-2"/>
          <Button v-if="summerFastDisciplines.length" label="Сформировать летний семестр"
                  @click="groupingModularDisciplines"
                  class="p-button-rounded mx-2"/>
        </div>


      </div>
      <!-- end credit count section -->


    </div>

    <div v-else>
      <h5 class="text-center mt-4">
        Здесь пусто
      </h5>
    </div>

  </div>
</template>


<script>

import {mapActions, mapMutations, mapState} from "vuex";
import {convertTimestampToDate} from "@/utils/helpers/convertTimestampToDate"
import educationprogram from "@/store/modules/education-programme/educationprogram";
import {decryptCheckRole} from "@/utils/helpers/decryptedRoles.helpers";


export default {
  name: "FormationEducationProgram",
  data() {
    return {
      urlEducationProgramId: this.$route.query.education_program_id || 0,
      urlDecreeGosoTemplateId: this.$route.query.decree_goso_template_id || 0,
      sumCredit: 0,
      remainderSum: 0,
      openSelectId: null,
      options: [],
      newModules: [],
      deleteEducationModuleId: null,
      filteredEducationSemesters: [],
      groupingIds: [],
      summerFastDisciplines: [],
      groupingModularIds: [],
      minorGroupingIds: [],
      loading: true,
      educationProgram: null,
      colorForFGroup: {},
      colors: ['#C5CAE9', '#FFCCBC', '#B2DFDB', '#FFECB3', '#C8E6C9', '#B3E5FC', '#FFCDD2', '#D7CCC8', '#F0F4C3', '#D1C4E9'],
      visibleBottom: false,
      kvIds: [6, 13, 21, 28, 34, 40, 47, 61, 63],
      year: '2024'
    }
  },
  computed: {
    ...mapState('formationEducationProgram', ['formationEducationProgram_form']),
    ...mapState('decreeGosoTemplates', ['decreeGosoTemplates_form']),
    ...mapState('gosoTemplates', ['gosoTemplate_form']),
    // minorGroupingIdsLength() {
    //   let isCvLength = minorGroupingIds.filter(i=>i.f_group_id != null).length
    //   let isMinorLength = minorGroupingIds.filter(i=>i.is_minor == 1).length
    //
    //   return isCvLength + isMinorLength
    // },
    isFgroupIdEqual() {
      let fGroupDisciplines = this.minorGroupingIds.filter(i => i.f_group_id !== null)
      //
      let isFgroupIdEqual = false
      // for (let i = 0; i < fGroupDisciplines.length; i++) {
      //   fGroupDisciplines[i].f_group_id
      // }

      if (fGroupDisciplines[0].f_group_id == fGroupDisciplines[1].f_group_id) {
        isFgroupIdEqual = true
      }
      return isFgroupIdEqual

    },
    isCreditEqual() {

      let fGroupDisciplines = this.minorGroupingIds.filter(i => i.f_group_id !== null)

      if ((fGroupDisciplines[0].credit === fGroupDisciplines[1].credit) && (fGroupDisciplines[1].credit == this.minorGroupingIds.find(i => i.is_minor == '1').credit)) {
        return true
      } else {
        return false
      }
    },
    isCvLength() {
      let isCvLength = this.minorGroupingIds.filter(i => i.f_group_id !== null).length
      return isCvLength;
    },
    isMinorLength() {
      let isMinorLength = this.minorGroupingIds.filter(i => i.is_minor == '1').length
      return isMinorLength;
    },
    allCreditsCount() {
      if (!this.formationEducationProgram_form) {
        return 0
      }
      return this.getCountableFormations(this.formationEducationProgram_form.formationEducationProgramInfos).reduce((sum, item) => +item.credit + sum, 0)
    },
    goso() {
      if (!this.formationEducationProgram_form) {
        return []
      }
      return this.formationEducationProgram_form.gosoTemplateCycle.map(cycle => {
        const formationsByCycleId = this.formationEducationProgram_form.formationEducationProgramInfos
            .filter(i => i.goso_cycle_name_id == cycle?.goso_cycle_name_id)

        const countableFormations = this.getCountableFormations(formationsByCycleId)

        return {
          cycleName: cycle?.gosoCycleName.short_name,
          components: [...new Set(countableFormations.map(i => i.goso_component_name_id))].map(id => ({
            name: this.formationEducationProgram_form.componentNames.find(i => i.id == id)?.short_name,
            credit: countableFormations.filter(i => i.goso_component_name_id == id).reduce((sum, item) => +item.credit + sum, 0)
          }))
        }
      })
    },

    allCreditsCountG() {
      if (!this.formationEducationProgram_form.gosoTemplateInfos) {
        return 0
      }
      return this.formationEducationProgram_form.gosoTemplateInfos.filter(i => i.parent_id != 0).reduce((sum, item) => +item.credit + sum, 0)
    },
    gosoG() {
      if (!this.formationEducationProgram_form.gosoTemplateInfos) {
        return 0
      }
      return this.formationEducationProgram_form.gosoTemplateInfos
          .filter(cycle => cycle.parent_id == 0)
          .map((cycle) => {
            let cycleName = cycle?.gosoCycleName.short_name
            return {
              cycleName,
              components: this.formationEducationProgram_form.gosoTemplateInfos
                  .filter(component => component.parent_id == cycle.id)
                  .map(component => {
                    let componentName = this.formationEducationProgram_form.componentNames.find(i => i.id == component.goso_components_name_id);
                    componentName = componentName?.short_name
                    return {
                      name: componentName,
                      credit: component.credit
                    }
                  })
            }
          })
    },

    checkSupervisor() {
      // return decryptCheckRole('educationprogramSupervisor') && !decryptCheckRole('departmentHead') && this.year !== this.educationProgram?.admission_year
      return decryptCheckRole('educationprogramSupervisor') && !decryptCheckRole('departmentHead') && false
    }

  },
  methods: {
    ...mapActions('decreeGosoTemplates', ['GET_DECREE_GOSO_TEMPLATES_DATA']),
    ...mapActions('gosoTemplates', ['GET_GOSO_TEMPLATE_DATA']),
    ...mapActions('educationmodule', ['DELETE_EDUCATION_MODULE_DATA']),
    ...mapActions('formationEducationProgram', ['GET_STUDY_LEVEL', 'GET_EDUCATION_PROGRAM', 'GET_GOSO_TEMPLATE_CYCLE', 'GET_EDUCATION_SEMESTER',
      'GET_FORMATION_EDUCATION_PROGRAM_DATA', 'GET_GOSO_CYCLE_NAME',
      'GET_GOSO_COMPONENTS_NAME', 'GET_EDUCATION_MODULE_DATA', 'GET_EDUCATION_DISCIPLINE_DATA',
      'POST_FORMATION_EDUCATION_PROGRAM_DATA', 'PUT_FORMATION_EDUCATION_PROGRAM_DATA', 'DELETE_FORMATION_EDUCATION_PROGRAM_DATA', 'DELETE_FORMATION_EDUCATION_PROGRAM_EDUCATION_MODULE',
      'SEARCH_DISCIPLINE', 'POST_MODULE', 'POST_GROUPING_DISCIPLINES', 'DELETE_FORMATION_FROM_GROUP', 'GET_GOSO_TEMPLATE_DATA', 'POST_GROUPING_MINOR_DISCIPLINES',
      'DELETE_FORMATION_FROM_MINOR_GROUP', 'POST_MODULAR_DISCIPLINES', 'DELETE_FORMATION_FROM_MODULE_GROUP']),
    ...mapMutations('formationEducationProgram', ['SET_STUDY_LEVEL_ID', 'SET_GOSO_TEMPLATE_DATA', 'SET_EDUCATION_PROGRAM_ID',
      'UPDATE_FORMATION_EDUCATION_PROGRAM_DATA', 'ADD_ROW_FORMATION_EDUCATION_PROGRAM_INFO', 'DELETE_ROW_FORMATION_EDUCATION_PROGRAM_INFO',
      'SET_DECREE_GOSO_TEMPLATES_ID', 'SET_URL_EDUCATION_PROGRAM_ID', 'SET_URL_DECREE_GOSO_TEMPLATE_ID',
      'SET_STUDY_LANG_ID']),
    convertTimestampToDate,
    async searchDisciplines(name, item) {
      if (name.length > 3) {
        const disciplines = await this.SEARCH_DISCIPLINE(name)
        this.UPDATE_FORMATION_EDUCATION_PROGRAM_DATA({item, property: 'disciplines', value: disciplines})
      }
    },
    getCountableFormations(formations) {
      const countableFormations = []
      const formationWithFGroupId = formations.filter(i => !!i.f_group_id)
      formationWithFGroupId.forEach(i => {
        if (!countableFormations.find(cI => cI.f_group_id == i.f_group_id)) {
          countableFormations.push(i)
        }
      })

      return [...formations.filter(i => !i.f_group_id && !i.minor_f_group_id), ...countableFormations]
    },
    addRowEducationModuleInfo() {
      this.newModules.push({
        module: 'Модуль ' + (this.newModules.length + 1),
        name: '',
        education_program_id: this.formationEducationProgram_form.urlEducationProgramId,
        decree_goso_templates_id: this.formationEducationProgram_form.urlDecreeGosoTemplateId
      })
    },
    deleteRowEducationModuleInfo(index) {
      this.newModules.splice(index, 1);
    },
    changeNewModules(item, property, e) {
      const index = this.newModules.indexOf(item)
      this.newModules[index][property] = e.target.value
    },
    async saveNewModules() {
      if (this.newModules.length) {
        const res = await this.POST_MODULE(this.newModules)
        if (res.success) {
          this.newModules = []
        }
      }
      await this.GET_EDUCATION_MODULE_DATA()
    },
    onSearch(search) {
      if (search.length > 3) {
        this.SEARCH_DISCIPLINE(search).then(json => (this.options = json));
      }
    },
    addRowFormationEducationProgram(education_module_id) {
      this.ADD_ROW_FORMATION_EDUCATION_PROGRAM_INFO(education_module_id)
      //this.SET_FORMATION_EDUCATION_PROGRAM_EDUCATION_MODULE_ID(this.formationEducationProgram_form.educationModules[0].id)
    },
    async deleteRowFormationEducationProgram(item) {
      if (item.id) {
        const {success} = await this.DELETE_FORMATION_EDUCATION_PROGRAM_DATA(item.id)
        if (success) {
          this.DELETE_ROW_FORMATION_EDUCATION_PROGRAM_INFO(item)
          this.$message({title: 'Удаление', text: 'Данные успешно удалены'})
        }
      } else {
        this.DELETE_ROW_FORMATION_EDUCATION_PROGRAM_INFO(item)
      }
    },
    openDeleteEducationModuleModal(id) {
      this.deleteEducationModuleId = id
    },
    async deleteEducationModule() {
      console.log(this.deleteEducationModuleId, 'DELETE EDUCATION MODULE ID')
      await this.DELETE_EDUCATION_MODULE_DATA(this.deleteEducationModuleId)
      await this.DELETE_FORMATION_EDUCATION_PROGRAM_EDUCATION_MODULE(this.deleteEducationModuleId)
      await this.GET_FORMATION_EDUCATION_PROGRAM_DATA()
      await this.GET_EDUCATION_MODULE_DATA()
    },
    changeFormationEducationProgramData(item, property, e, val = 'value') {
      if (property === 'education_discipline_id') {
        console.log('education_discipline_id', e)
      }

      const value = property === 'education_discipline_id' ? e.value : e.target[val]
      this.UPDATE_FORMATION_EDUCATION_PROGRAM_DATA({item, property, value})
    },
    changeSelectFormationEducationProgramData(item, property, value) {
      this.UPDATE_FORMATION_EDUCATION_PROGRAM_DATA({item, property, value})
      this.minorGroupingIds = []
      this.minorGroupingIds = []
    },
    async putFormation(id) {
      const formation = this.formationEducationProgram_form.formationEducationProgramInfos.find(i => i.id == id && i.edit_status == 1)
      if (formation) {
        const res = await this.PUT_FORMATION_EDUCATION_PROGRAM_DATA(formation)
        if (res.success == true) {
          await this.GET_FORMATION_EDUCATION_PROGRAM_DATA()
          this.$message({title: 'Обновление', text: 'Данные успешно обновились'})
        } else {
          const errorText = res.errors[0].map(err => err.message).join('\n');
          this.$error({title: 'Обновление', text: errorText})
        }
      }
    },
    async submitFormationEducationProgram() {
      const postFormationEducationProgram = this.formationEducationProgram_form.formationEducationProgramInfos.filter(v => v.id == undefined)
      console.log(postFormationEducationProgram, 'postFormationEducationProgram')
      if (postFormationEducationProgram) {
        await this.POST_FORMATION_EDUCATION_PROGRAM_DATA().then(res => {
          if (res.success == true) {
            this.GET_FORMATION_EDUCATION_PROGRAM_DATA()
            this.$message({title: 'Добавление', text: 'Данные успешно добавились'})
            this.openSelectId = null
          } else {
            const errorText = res.errors[0].map(err => err.message).join('\n');
            this.$error({title: 'Добавление', text: errorText})
          }
        })
      }
    },
    async groupingDisciplines() {
      console.log(this.groupingIds, 'groupingIds')
      const res = await this.POST_GROUPING_DISCIPLINES({ids: this.groupingIds})
      if (res) {
        await this.GET_FORMATION_EDUCATION_PROGRAM_DATA()
        this.bindColorForFormation()
        this.groupingIds = []
        this.$message({text: 'Успешная группировка'})
      }
    },

    async groupingModularDisciplines() {
      const res = await this.POST_MODULAR_DISCIPLINES(this.groupingModularIds)

      if (res) {
        await this.GET_FORMATION_EDUCATION_PROGRAM_DATA()
        this.bindColorForFormation()
        this.groupingModularIds = []
        this.$message({text: 'Успешная группировка'})
      }
    },

    async groupingMinorDisciplines() {
      console.log(this.minorGroupingIds, 'minorGroupingIds')
      console.log(this.isMinorLength, 'isMinorLength')
      console.log(this.isCvLength, 'isCvLength')
      console.log(this.isCreditEqual, 'isCreditEqual')
      const res = await this.POST_GROUPING_MINOR_DISCIPLINES({ids: this.minorGroupingIds})
      if (res) {
        await this.GET_FORMATION_EDUCATION_PROGRAM_DATA()
        this.bindColorForFormation()
        this.minorGroupingIds = []
        this.$message({text: 'Успешная группировка майноров'})
      }
    },
    async deleteFormationFromGroup(item) {


      if (item.minor_f_group_id == null) {
        const res = await this.DELETE_FORMATION_FROM_GROUP(item.f_group_id)
        if (res) {
          await this.GET_FORMATION_EDUCATION_PROGRAM_DATA()
          this.bindColorForFormation()
          this.$message({text: 'Группа успешно удалено'})
        }
      } else {
        this.$error({text: 'Прежде чем удалить группировку КВ удалите группировку майноров'})
      }

    },

    async deleteFormationFromMinorGroup(minor_f_group_id) {
      const res = await this.DELETE_FORMATION_FROM_MINOR_GROUP(minor_f_group_id)
      if (res) {
        await this.GET_FORMATION_EDUCATION_PROGRAM_DATA()
        this.bindColorForFormation()
        this.$message({text: 'Группа майнора успешно удалена'})
      }
    },

    async deleteFormationFromModuleGroup(module_group_id) {
      const res = await this.DELETE_FORMATION_FROM_MODULE_GROUP(module_group_id)
      if (res) {
        await this.GET_FORMATION_EDUCATION_PROGRAM_DATA()
        this.bindColorForFormation()
        this.$message({text: 'Группа модульных дисциплин успешно удалена'})
      }
    },

    bindColorForFormation() {
      const fGroups = [...new Set(this.formationEducationProgram_form.formationEducationProgramInfos.filter(i => !!i.f_group_id).map(i => i.f_group_id))]
      fGroups.forEach((item, index) => {
        this.colorForFGroup[item] = this.colors[index % 10]
      })
    },
  },

  async mounted() {
    this.SET_URL_EDUCATION_PROGRAM_ID(this.urlEducationProgramId);
    this.SET_URL_DECREE_GOSO_TEMPLATE_ID(this.urlDecreeGosoTemplateId);
    await this.GET_EDUCATION_PROGRAM(this.urlEducationProgramId);

    let firstEducationProgram = this.formationEducationProgram_form.educationPrograms[0];
    if (firstEducationProgram) {
      this.educationProgram = firstEducationProgram
      this.SET_EDUCATION_PROGRAM_ID(firstEducationProgram.id)
      this.SET_STUDY_LEVEL_ID(this.educationProgram.study_level_id)
      this.SET_STUDY_LANG_ID(this.educationProgram.language_id)
    }


    await this.GET_FORMATION_EDUCATION_PROGRAM_DATA()
    await this.GET_GOSO_TEMPLATE_CYCLE()
    await this.GET_EDUCATION_SEMESTER()

    let filteredEducationSemesters = this.formationEducationProgram_form.educationSemesters.filter(i => i.education_program_semester_type_id == this.educationProgram.education_program_semester_type_id)
    if (filteredEducationSemesters && firstEducationProgram) {
      if (+firstEducationProgram.department_id === 5) {
        filteredEducationSemesters.splice(8)
      } else if (+firstEducationProgram.department_id !== 40) {
        filteredEducationSemesters.splice(8)
      }
    }
    this.filteredEducationSemesters = filteredEducationSemesters

    await this.GET_EDUCATION_MODULE_DATA()
    await this.GET_DECREE_GOSO_TEMPLATES_DATA()

    this.SET_DECREE_GOSO_TEMPLATES_ID(this.formationEducationProgram_form.urlDecreeGosoTemplateId)

    await this.GET_STUDY_LEVEL()
    await this.GET_GOSO_COMPONENTS_NAME()
    // await this.GET_GOSO_CYCLE_NAME()

    // await this.SET_STUDY_LEVEL_ID(this.educationProgram.study_level_id)
    await this.GET_GOSO_TEMPLATE_DATA()

    console.log(this.formationEducationProgram_form.gosoTemplateInfos, 'gosoTemplateInfos')

    this.bindColorForFormation()
    this.loading = false
  }
}
</script>

<style scoped>
#groupingIds {
  width: 1.3em;
  height: 1.3em;
}

#summerFastDisciplines {
  color: red;
  width: 1.3em;
  height: 1.3em;
}

#groupingModularIds {
  width: 1.5em;
  height: 1.5em;
  background-color: white;
  border-radius: 50%;
}

#groupingModularIds:checked {
  background-color: gray;
}

#minorGroupingIds {
  position: relative;
  width: 1.3em;
  height: 1.3em; /* Высота элемента input */
  transform: rotate(45deg); /* Повернуть элемент input на 45 градусов */
   /* Цвет и толщина границы */
  box-sizing: border-box; /* Учитывать толщину границы в расчетах размеров */
}

#minorGroupingIds::before {
  content: '';
  position: absolute;
  top: -15px; /* Поднять на половину высоты элемента input */
  left: -15px; /* Сместить на половину ширины элемента input */
  width: 60px; /* Удвоенная ширина элемента input */
  height: 60px; /* Удвоенная высота элемента input */
  transform: rotate(45deg); /* Повернуть на 45 градусов, чтобы получить ромб */
  box-sizing: border-box; /* Учитывать толщину границы в расчетах размеров */
}

</style>
